.page-template-article {

  $base-breakpoint: 850px;

  .article {

    &__wrapper {
      @include wrapper;
      display: grid;
      grid-template-areas: "title sidebar"
                           "content sidebar";
      grid-template-columns: auto 300px;
      grid-column-gap: 3rem;

      @include media($base-breakpoint, max) {
        grid-template-areas: "title"
                             "content"
                             "sidebar";
        grid-template-columns: 1fr;
        padding: 0;
      }
    }

    &__title {
      grid-area: title;
      margin-top: -280px;
      padding: 280px 3rem 2rem 3rem;
      background-color: #4eae53;
      background-repeat: no-repeat;
      background-position: top right;
      z-index: 10;

      h1 {
        font-family: $font-secondary;
        color: white;
        font-size: 2.06rem;
        margin: 0;
        max-width: 640px;
        font-weight: 400;
      }

      .backlink {
        display: flex;
        align-items: center;
        margin-top: 1rem;

        &::before {
          display: block;
          width: 14px;
          height: 14px;
          content: "";
          background-image: url("./../images/common/arrow-left.svg");
          background-repeat: no-repeat;
          background-size: 14px 14px;
          margin-right: 5px;
          transform: translateY(1px);
        }

        &__link {
          font-size: 0.9rem;
          color: white;
          text-decoration: underline;
          line-height: 14px;
          display: inline-block;
        }
      }
    }

    &__date {
      color: white;
      font-size: 0.82rem;
      transform: translateY(-9px);
      display: inline-block;
    }

    &__content {
      grid-area: content;
      margin: 2rem 3rem;

      @include media($base-breakpoint, max) {
        margin: 2rem;
      }

      img {
        width: 100%;
      }

      .ccm-block-share-this-page {
        margin-top: 2rem;
      }
    }

    &__sidebar {
      grid-area: sidebar;
      position: relative;
      margin-bottom: -152px;

      @include media($base-breakpoint, max) {
        padding: 0 2.5rem;
        margin-bottom: 0;
      }

      .article-sidebar {

        &__content {
          width: 260px;
          min-height: 250px;
          background-color: white;
          padding: 1.5rem 2rem;
          position: sticky;
          top: 350px;
          transform: translate(-90px, -200px);
          z-index: 15;
          box-shadow: 0 2px 15px 0 rgba(50, 50, 50, 0.12);

          @include media($base-breakpoint, max) {
            transform: initial;
            position: relative;
            top: initial;
            width: 100%;
            margin-bottom: -130px;
          }

          p {
            font-size: 0.8rem;
          }

          h4 {
            margin-bottom: 1rem;
          }

          a {
            text-decoration: underline;
          }

          .contact-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin-bottom: 1.5rem;

            p {
              margin: 0;
            }

            a {
              font-weight: 600;
            }
          }
        }

        &__image {
          position: absolute;
          top: 0;
          width: 100%;
          margin-top: 3rem;
          z-index: 10;
          display: flex;
          justify-content: flex-end;

          @include media($base-breakpoint, max) {
            position: relative;
            margin-bottom: -200px;

            &_one, &_two {
              width: 140px;
            }
          }

          &_one {
            @include media($base-breakpoint, max) {
                position: absolute;
                right: 0;
            }
          }

          &_two {
            position: absolute;
            right: 0;
            transform: translateX(-84px);

            @include media($base-breakpoint, max) {
              position: relative;
              left: initial;
              transform: translateX(-70px);
            }
          }
        }
      }
    }
  }

  .featured {
    background-color: $brand-gray-lt;
    min-height: 200px;

    @include media($base-breakpoint, max) {
      padding-top: 180px;
    }

    &__wrapper {
      @include wrapper;
      padding: 2rem 3rem;
    }

    &__header {
      text-align: center;
    }
  }
}