.footer {
  color: white;
  font-size: 14px;
  background-color: $brand-gray;
  padding: 5rem 0;

  p {
    margin-top: 0;
    margin-bottom: 0;

    strong {
      font-weight: 600;
    }
  }

  &__wrapper {
    @include wrapper;
    display: grid;
    grid-template-areas: "logo . . ."
                         "info . offices offices";
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 2rem;

    @include media(800px, max) {
      grid-template-areas: "logo"
                           "info"
                           "offices";
      grid-template-columns: 1fr;
    }
  }

  &__logo {
    grid-area: logo;
    width: 161px;
  }

  &__info {
    grid-area: info;
    max-width: 300px;
  }

  &__copy {
    color: lighten($brand-gray-md, 10);
    font-size: 0.8rem;
  }

  &__offices {
    display: grid;
    grid-area: offices;
    width: 100%;
    max-width: 500px;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    justify-items: end;

    @include media($screen-sm, max) {
      grid-template-columns: 1fr;
      justify-items: start;
    }
  }
}