.our-people {

  &__form {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    max-width: 800px;
    grid-gap: 1rem;
    margin-bottom: 3rem;

    @include media($screen-sm, max) {
      grid-template-columns: 1fr;
      margin-bottom: 5rem;
    }

    input, select {
      color: darken($brand-gray-lt, 10);
      font-family: $font-primary;
      font-weight: 500;
      width: 100%;
      border: 2px solid $brand-gray-md;
      padding: 0.47rem 15px;
      font-size: 0.9rem;
      outline: none;
      background: transparent;
    }

    option {
      background-color: $brand-gray-md;
    }
  }

  .search-form {

    &__full-name {
      position: relative;

      input[type='text'] {
        padding: 0.47rem 35px 0.47rem 15px;
      }

      .loupe {
        background-image: url("./../images/common/loupe.svg");
        background-size: 100%;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-10px, -50%);
        cursor: pointer;
      }
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, 286px);
    grid-gap: 2rem;

    @include media($screen-md, max) {
      grid-template-columns: repeat(auto-fit, 314px);
      justify-content: center;
    }
  }

  &__message {
    padding-top: 8rem;
    text-align: center;
  }

  .person-card {
    position: relative;
    overflow: hidden;
    background-color: $brand-gray-lt;

    &__photo {
      position: relative;
    }

    &__info {
      padding: 2rem 1.5rem;

      p {
        margin: 0;
      }
    }

    &__full-name {
      font-family: $font-secondary;
      font-weight: 500;
    }

    &__position {
      padding-bottom: 1rem;
    }

    &__qualification, &__location {
      font-size: 0.9rem;
    }

    &__contacts {
      position: relative;
      font-size: 0.9rem;
      //max-height: 0;
      //opacity: 0;
      //visibility: collapse;
      margin-top: 1rem;
      transition: 0.1s opacity ease;

      a {
        font-weight: 500;
      }

      //&::after {
      //  display: block;
      //  content: "";
      //  background-image: url("./../images/common/chevron-right.svg");
      //  background-size: 100%;
      //  width: 24px;
      //  height: 35px;
      //  position: absolute;
      //  right: 0;
      //  top: 50%;
      //  transform: translateY(calc(-50% + 0.5rem));
      //}
    }

    &__link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &:hover {

      //.person-card__photo {
      //  margin-top: -51px;
      //}

      //.person-card__contacts {
      //  max-height: 100%;
      //  visibility: visible;
      //  opacity: 1;
      //}
    }
  }
}