.headline {
  background-color: $brand-blue;
  min-height: 280px;
  background-repeat: no-repeat;
  background-position: center right;
  background-size: auto 280px;
  position: relative;

  @include media ($screen-sm, max) {
    background-position: top right;
    background-size: auto 280px;
  }

  &__wrapper {
    @include wrapper;
    padding: 5rem 5rem 0 5rem;

    @include media ($screen-sm, max) {
      padding: 3rem 2rem 3rem 2rem;
    }

    &_with-image {
      @include media ($screen-sm, max) {
        padding: 300px 2rem 3rem 2rem;
      }
    }
  }

  &__title {
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1.8px;
    font-size: 1.14rem;
    position: relative;
    padding-left: calc(22px + 1rem);
    margin: 1rem 0;

    &::before {
      display: block;
      content: "";
      background-image: url("./../images/common/chevron-right.svg");
      background-size: 100%;
      width: 15px;
      height: 22px;
      position: absolute;
      left: 0;
      bottom: 50%;
      transform: translateY(50%);
    }
  }

  &__description {
    font-family: $font-secondary;
    color: white;
    font-size: 2.06rem;
    margin: 0;
    max-width: 640px;
    font-weight: 400;
  }

  .headline-image {
    position: absolute;
    top: 0;
    right: 580px;

    &_one, &_two {
      position: absolute;
    }

    &_one {
      left: 87px;
    }
  }
}