.page-template-expertise {

  .lead {
    position: relative;
    z-index: 10;

    &__wrapper {
      width: 100%;
      max-width: $screen-lg;
      margin: 0 auto;
      padding-left: 150px;
      box-sizing: border-box;
      display: grid;
      grid-template-areas: "content image";
      grid-template-columns: 1fr 1fr;

      @include media($screen-md, max) {
        grid-template-areas: "content"
        "image";
        grid-template-columns: 1fr;
        padding-left: 0;
      }
    }

    &__content {
      grid-area: content;
      padding: 5rem;

      @include media($screen-md, max) {
        padding: 3rem 2rem 0 2rem;
      }
    }

    &__image {
      grid-area: image;
      align-self: center;

      img {
        max-width: 500px;
      }
    }
  }

  .expertise {
    background-color: $brand-gray-lt;
    padding: 5rem 1rem;
    position: relative;
    z-index: 20;

    @include media($screen-md, max) {
      margin-top: -2rem;
    }

    &__wrapper {
      @include wrapper;
    }
  }
}