// Responsive Breakpoints
$screen-xs: 320px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1366px;
$screen-xl: 1920px;

// Content Box
$wrapper-max-width: 1064px;

// Colors
$brand-gray: #333333;
$brand-gray-md: #707070;
$brand-gray-lt: #f0f0f0;
$brand-green: #71B147;
$brand-green-lt: #d9eddb;
//$brand-blue: #06487D;
$brand-blue: #004680;
