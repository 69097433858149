.subscription {
  color: white;
  background-color: $brand-blue;
  min-height: 300px;

  &__wrapper {
    @include wrapper;
    position: relative;
    padding: 5rem 0;

    &::after {
      display: block;
      content: "";
      background-image: url("./../images/home/subscription_bg.png");
      background-size: 100%;
      background-repeat: no-repeat;
      width: 151px;
      height: 214px;
      position: absolute;
      top: 0;
      right: 0;

      @include media($screen-md, max) {
        width: 110px;
        height: 192px;
        background-image: url("./../images/home/subscription_bg_md.svg");
        top: 5rem;
        right: 50%;
        transform: translateX(50%);
      }
    }
  }

  &__content {
    @include media($screen-md, max) {
      margin-top: calc(5rem + 160px);
    }

    p {
      margin: 0.5rem 0;
    }
  }
}