.page-template-profile {

  $base-breakpoint: 850px;

  .headline {
    background-color: $brand-gray;
    min-height: 359px;
  }

  .profile {

    &__wrapper {
      @include wrapper;
      display: grid;
      grid-template-areas: "title sidebar"
      "content sidebar";
      grid-template-columns: auto 300px;
      grid-column-gap: 3rem;

      @include media($base-breakpoint, max) {
        grid-template-areas: "title"
        "sidebar"
        "content";
        grid-template-columns: 1fr;
        padding: 0;
      }
    }

    &__title {
      grid-area: title;
      margin-top: -359px;
      padding: 5rem 5rem 3rem 5rem;
      background-color: $brand-blue;
      background-repeat: no-repeat;
      background-position: top right;
      min-height: 429px;
      z-index: 10;
      position: relative;

      @include media($base-breakpoint, max) {
        padding: 2rem 2rem 3rem 2rem;
      }

      h1 {
        font-family: $font-secondary;
        color: white;
        font-size: 2.06rem;
        margin: 0;
        max-width: 640px;
        font-weight: 400;
      }

      h2 {
        color: white;
        margin: 0.5rem 0;
        font-weight: 400;
      }

      h4, p {
        color: white
      }

      .backlink {
        display: flex;
        align-items: center;
        margin-top: 1rem;
        margin-bottom: 3rem;

        &::before {
          display: block;
          width: 14px;
          height: 14px;
          content: "";
          background-image: url("./../images/common/arrow-left-green.svg");
          background-repeat: no-repeat;
          background-size: 14px 14px;
          margin-right: 5px;
          transform: translateY(1px);
        }

        &__link {
          font-size: 0.9rem;
          color: $brand-green;
          text-decoration: underline;
          line-height: 14px;
          display: inline-block;
        }
      }
    }

    &__content {
      grid-area: content;
      padding: 3rem 5rem;

      @include media($base-breakpoint, max) {
        margin: 2rem;
        padding: 0;
      }

      img {
        width: 100%;
      }

      ul {

        li {
          display: flex;
          align-items: center;
          margin-bottom: 15px;

          &::before {
            display: block;
            content: "";
            background-image: url("./../images/common/chevron-right.svg");
            background-size: 100%;
            width: 7px;
            height: 11px;
            margin-right: 10px;
            background-repeat: no-repeat;
          }
        }
      }
    }

    &__sidebar {
      grid-area: sidebar;
      position: relative;
      margin-bottom: -232px;

      @include media($base-breakpoint, max) {
        margin-top: -3rem;
        justify-self: center;
        margin-bottom: 0;
      }

      .profile-sidebar {

        &__content {
          width: 290px;
          min-height: 250px;
          background-color: white;
          position: sticky;
          top: 350px;
          transform: translate(-90px, -280px);
          z-index: 15;
          box-shadow: 0 2px 15px 0 rgba(50, 50, 50, 0.12);

          @include media($base-breakpoint, max) {
            transform: initial;
            position: relative;
            top: initial;
          }
        }

        &__photo {
          max-height: 280px;
          object-fit: cover;
          width: 100%;
        }

        &__info {
          font-size: 0.9rem;
          padding: 1.5rem 2rem;

          a, .bold {
            font-weight: 500;
          }
        }
      }
    }
  }

}