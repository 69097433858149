.page-template-expertise-item {

  $base-breakpoint: 850px;

  .headline {

    &__description {
      max-width: 350px;
    }
  }

  .information {
    position: relative;

    &__wrapper {
      @include wrapper;
      display: grid;
      grid-template-areas: "content sidebar";
      grid-template-columns: auto 300px;
      grid-column-gap: 4rem;
      padding: 0 2rem;
      position: relative;

      @include media($base-breakpoint, max) {
        grid-template-areas: "title"
        "content"
        "sidebar";
        grid-template-columns: 1fr;
        padding: 0;
      }
    }

    &__content {
      grid-area: content;
      margin: 2rem 3rem;

      @include media($base-breakpoint, max) {
        margin: 2rem;
      }

      .backlink {
        display: flex;
        align-items: center;
        margin-top: 1rem;
        margin-bottom: 2rem;

        &::before {
          display: block;
          width: 14px;
          height: 14px;
          content: "";
          background-image: url("./../images/common/arrow-left-green.svg");
          background-repeat: no-repeat;
          background-size: 14px 14px;
          margin-right: 10px;
        }

        &__link {
          text-decoration: underline;
          line-height: 14px;
          display: inline-block;
        }
      }

      img {
        width: 100%;
      }

      .ccm-block-share-this-page {
        margin-top: 2rem;
      }
    }

    &__sidebar {
      grid-area: sidebar;
      position: relative;
      font-size: 0.9rem;
      z-index: 15;

      @include media($base-breakpoint, max) {
        padding: 0 2.5rem;
      }

      .information-sidebar {

        &__content {
          width: 260px;
          min-height: 250px;
          position: sticky;
          top: 350px;
          transform: translate(0, -200px);
          z-index: 15;
          box-shadow: 0 2px 15px 0 rgba(50, 50, 50, 0.12);

          @include media($base-breakpoint, max) {
            transform: initial;
            position: relative;
            top: initial;
            width: 100%;
            margin-bottom: -130px;
          }

          p {
            font-size: 0.8rem;
          }

          h4 {
            margin-bottom: 1rem;
          }

          a {
            text-decoration: underline;
          }

          .contact-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin-bottom: 1.5rem;

            p {
              margin: 0;
            }

            a {
              font-weight: 600;
            }
          }
        }

        &__wrapper {
          background-color: white;
          padding: 1.5rem 2rem;
          position: relative;
          z-index: 15;
        }
      }

      .sidebar-bg-figure {
        position: absolute;
        z-index: 5;

        &_bg-variant-one {
          display: block;
          width: 277px;
          height: 277px;
          content: "";
          background-image: url("./../images/expertise_item/bg-variant-one/sidebar-bg-figure.svg");
          top: 300px;
          right: 0;
          transform: translate(80px, 0);

          @include media($base-breakpoint, max) {
            display: none;
          }
        }

        &_bg-variant-two {
          width: 466px;
          height: 173px;
          top: 350px;
          transform: translateX(150px);

          @include media($screen-md, max) {
            display: none;
          }

          .sidebar-bg-figure__one {
            position: absolute;
            top: 0;
            left: 0;
            width: 233px;
            height: 173px;
            background-image: url("./../images/expertise_item/bg-variant-two/sidebar-bg-figure-one.svg");
            background-repeat: no-repeat;
            background-size: 233px 173px;
          }

          .sidebar-bg-figure__two {
            position: absolute;
            top: 0;
            left: 233px;
            width: 233px;
            height: 173px;
            background-image: url("./../images/expertise_item/bg-variant-two/sidebar-bg-figure-two.svg");
            background-repeat: no-repeat;
            background-size: 233px 173px;
          }
        }
      }
    }

    .information-bg-figure {
      position: absolute;

      &_bg-variant-one {
        top: 66%;
        left: 300px;
        width: 550px;
        height: 550px;
        transform: translateY(-50%);

        @include media($screen-sm, max) {
          left: 260px;
        }

        .information-bg-figure__one, .information-bg-figure__two, .information-bg-figure__three, .information-bg-figure__four {
          width: 323px;
          height: 323px;
          position: absolute;
          background-repeat: no-repeat;
          background-size: 323px 323px;
          z-index: 5;
        }

        .information-bg-figure__one {
          top: 0;
          left: 0;
          background-image: url("./../images/expertise_item/bg-variant-one/content-bg-figure-one.svg");
        }

        .information-bg-figure__two {
          top: 0;
          right:0;
          background-image: url("./../images/expertise_item/bg-variant-one/content-bg-figure-two.svg");
        }

        .information-bg-figure__three {
          bottom: 0;
          left: 0;
          background-image: url("./../images/expertise_item/bg-variant-one/content-bg-figure-three.svg");
        }

        .information-bg-figure__four {
          bottom: 0;
          right: 0;
          background-image: url("./../images/expertise_item/bg-variant-one/content-bg-figure-four.svg");
        }

        .information-bg-figure__five {
          position: absolute;
          z-index: 4;
          top: 0;
          right: 0;
          transform: translate(200px, 71px);
          width: 361px;
          height: 390px;
          background-image: url("./../images/expertise_item/bg-variant-one/content-bg-figure-five.png");
        }
      }

      &_bg-variant-two {
        top: 70%;
        left: 750px;
        width: 940px;
        height: 350px;
        transform: translateY(-50%);

        @include media($screen-md, max) {
          left: 250px;
          width: 470px;
          height: 175px;
          transform: translateY(-50%) rotate(90deg);
        }

        .information-bg-figure__one, .information-bg-figure__two, .information-bg-figure__three {
          position: absolute;
          background-repeat: no-repeat;
          z-index: 5;
        }

        .information-bg-figure__one, .information-bg-figure__two {
          width: 470px;
          height: 350px;
          top: 0;

          @include media($screen-md, max) {
            width: 235px;
            height: 175px;
            background-size: 100%;
          }
        }

        .information-bg-figure__one {
          left: 0;
          background-image: url("./../images/expertise_item/bg-variant-two/content-bg-figure-one.svg");
        }

        .information-bg-figure__two {
          left: 470px;
          background-image: url("./../images/expertise_item/bg-variant-two/content-bg-figure-two.svg");

          @include media($screen-md, max) {
            left: 235px;
          }
        }

        .information-bg-figure__three {
          width: 412px;
          height: 220px;
          bottom: 0;
          left: 50%;
          background-image: url("./../images/expertise_item/bg-variant-two/content-bg-figure-three.png");
          transform: translate(-50%, 110px);

          @include media($screen-md, max) {
            display: none;
          }
        }
      }
    }
  }

  .expertise {
    @include section;
    background-color: $brand-gray-lt;

    @include media($base-breakpoint, max) {
      padding: 180px 0 2rem 0;
    }

    &__wrapper {
      @include wrapper;
    }
  }
}