@import "templates/articles_search/view";

.search-block {

  &__form {

  }

  &__wrapper {
    display: grid;
    grid-template-columns: 49px auto;
  }

  &__submit {
    color: white;
    font-family: FontAwesome;
    font-size: 1.5rem;
    border: none;
    background-color: $brand-green;
    min-height: 54px;
    cursor: pointer;
    border-radius: 0;
    -webkit-appearance: none;
  }

  &__text {
    border-left: none;
    border-top: 1px solid lighten($brand-gray-md, 30);
    border-right: 1px solid lighten($brand-gray-md, 30);
    border-bottom: 1px solid lighten($brand-gray-md, 30);
    padding: 0.5rem 1rem;
    font-size: 1rem;
    outline: none;
    -webkit-appearance: none;
    border-radius: 0;
  }
}