.page-template-contact {

  .locations {
    padding: 3rem 0;

    &__wrapper {
      @include wrapper;
      display: grid;
      grid-template-areas: "map info";
      grid-gap: 85px;
      grid-template-columns: 632px 260px;

      @include media(1105px, max) {
        grid-template-columns: 1fr 260px;
        grid-gap: 2rem;
      }

      @include media($screen-sm, max) {
        grid-template-areas: "info"
                             "map";
        grid-template-columns: 1fr;
      }
    }

    &__map {
      grid-area: map;
      height: 319px;
      background-color: $brand-gray-md;
    }

    .locations-info {
      grid-area: info;
      position: relative;

      &__wrapper {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 2rem;
        background-color: white;
        width: 260px;
        box-shadow: 0 2px 15px 0 rgba(50, 50, 50, 0.12);
        box-sizing: border-box;

        @include media(1105px, max) {
          right: 0;
        }

        @include media($screen-sm, max) {
          position: relative;
          top: initial;
          right: initial;
          box-shadow: none;
          padding: 0;
        }

        p {
          margin: 0;
          line-height: 1.3rem;

          strong {
            font-family: $font-secondary;
            font-weight: 500;
            margin-bottom: 10px;
            display: inline-block;
          }

          a {
            margin: 5px 0;
            display: inline-block;
            text-decoration: underline;
            font-weight: 500;

            &.link-button {
              padding: 0.2rem 2rem 0.4rem 2rem;
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  .enquiry {
    padding: 5rem 2rem;
    background-color: $brand-gray-lt;
  }

}