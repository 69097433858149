@import "settings/variables";
@import "settings/mixins";
@import "settings/typography";
//@import "bootstrap-grid.css";

// Common Elements
@import "elements/header";
@import "elements/footer";
@import "elements/headline";
@import "elements/subscription";

// Blocks
@import "blocks/autonav/view";
@import "blocks/image_slider/view";
@import "blocks/image_slider/templates/prefooter_slider/view";
@import "blocks/page_list/view";
@import "blocks/search/view";
@import "blocks/express_form/view";
@import "blocks/featured_experts_list/view";
@import "blocks/express_entry_list/view";

// Layouts
@import "layouts/home";
@import "layouts/full";
@import "layouts/contact";
@import "layouts/firm";
@import "layouts/careers";
@import "layouts/expertise";
@import "layouts/our_people";
@import "layouts/article";
@import "layouts/expertise_item";
@import "layouts/profile";
@import "layouts/vacancy";

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: $font-primary;
  color: $brand-gray;
  font-size: 16px;
  margin: 0;
  overflow-x: hidden;

  &.no-overflow {
    overflow-y: hidden;
  }
}

a {
  color: $brand-green;
  text-decoration: none;
  transition: 0.3s color ease;

  &:hover {
    color: darken($brand-green, 10);
  }

  &.underlined {
    text-decoration: underline;
  }
}

strong {
  font-weight: 500;
}

.hidden-sm {
  @include media($screen-sm, max) {
    display: none !important;
  }
}

.visible-sm {
  display: none !important;
  @include media($screen-sm, max) {
    display: inline-block !important;
  }
}

.hidden-md {
  @include media($screen-md, max) {
    display: none !important;
  }
}

.visible-md {
  display: none !important;
  @include media($screen-md, max) {
    display: inline-block !important;
  }
}

.text-green {
  color: $brand-green;
}

.text-gray {
  color: $brand-gray;
}

.text-callout {
  font-family: $font-secondary;
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-weight: 400;

  &.font-primary {
    font-family: $font-primary;
  }
}

.text-leading {
  font-family: $font-leading;
  font-size: 1.06rem;
  font-weight: 300;
}

.text-heading {
  font-family: $font-primary;
  font-weight: 300;
  font-size: 1.5rem;
}

.section-tagline {
  color: $brand-blue;
  font-family: $font-primary;
  font-size: 1.23rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2.16px;
  margin: 0;
}

.link-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: $font-primary;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 2rem;
  padding: 0.4rem 1rem 0.6rem 1rem;
  transition: 0.3s background-color ease, 0.3s color ease, 0.3s border ease;
  cursor: pointer;
  text-decoration: none !important;

  i.fa {
    font-size: 0.875rem;
    margin-right: 8px;
  }

  &_green {
    color: $brand-green;
    background-color: white;
    border: 1px solid $brand-green;

    &.transparent {
      background-color: transparent;
    }

    &:hover {
      color: white;
      background-color: $brand-green;
    }
  }

  &_white {
    color: white;
    border: 1px solid white;

    &:hover {
      color: white;
      background-color: $brand-green;
      border-color: $brand-green;
    }
  }
}

ul {
  list-style: disc;
  margin: 0;
  padding: 0;
}

.img-responsive {
  width: 100%;
  vertical-align: bottom;
}

hr.separator {
  margin: 2rem 0;
  border: 0;
  height: 1px;
  background: $brand-gray-lt;
}

main {

  ul {

    &.list-inline {
      display: inline-block;

      li {

        &::before {
          display: none;
        }
      }
    }

    li {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      position: relative;
      margin-left: 20px;

      &::before {
        display: block;
        content: "";
        background-image: url('./../images/common/chevron-right.svg');
        background-size: 100%;
        width: 7px;
        height: 11px;
        margin-right: 10px;
        background-repeat: no-repeat;
        position: absolute;
        left: -20px;
      }
    }

    &.base-list {
      margin: 2rem 0;

      li {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        &::before {
          display: block;
          content: "";
          background-image: url('./../images/common/chevron-right.svg');
          background-size: 100%;
          width: 7px;
          height: 11px;
          margin-right: 10px;
          background-repeat: no-repeat;
        }
      }
    }
  }

  .ccm-pagination-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 5rem;

    ul.pagination {
      display: flex;

      li {
        &::before, &:after {
          display: none;
        }
      }
    }
  }
}