.articles-page-list {
  max-width: 984px;
  margin: 0 auto;

  &__header {
    text-align: center;
    margin: 2rem 0;
  }

  &__pages {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 3rem;

    @include media($screen-sm, max) {
      grid-template-columns: 1fr;
    }
  }

  .page-list-item {
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid lighten($brand-gray-md, 20);

    &__thumbnail {

    }

    &__text {
      display: grid;
      grid-row-gap: 10px;
      grid-template-areas: "date"
                           "title"
                           "link";
    }

    &__title {
      font-family: $font-secondary;
      font-size: 1.1rem;
      grid-area: title;
    }

    &__date {
      grid-area: date;
      font-size: 0.9rem;
    }

    &__description {
      grid-area: description;
    }

    &__link {
      grid-area: link;
    }
  }
}