.page-template-vacancy {

  $base-breakpoint: 850px;

  .headline {
    background-color: $brand-blue;
    min-height: 207px;
  }

  .vacancy {
    @include media($base-breakpoint, max) {
      background-color: $brand-gray-lt;
    }

    &__wrapper {
      @include wrapper;
      display: grid;
      grid-template-areas: "title sidebar"
      "content sidebar";
      grid-template-columns: auto 260px;
      grid-column-gap: 2rem;

      @include media($base-breakpoint, max) {
        grid-template-areas: "title"
        "content"
        "sidebar";
        grid-template-columns: 1fr;
        padding: 0;
      }
    }

    &__title {
      grid-area: title;
      margin-top: -207px;
      padding: 5rem 5rem 0 5rem;
      background-color: $brand-gray-lt;
      background-repeat: no-repeat;
      background-position: top right;

      @include media($base-breakpoint, max) {
        padding: 3rem 2rem 1rem 2rem;
      }

      h1 {
        font-family: $font-secondary;
        font-size: 2.06rem;
        margin: 0;
        max-width: 640px;
        font-weight: 400;
      }

      h2 {
        margin: 0.5rem 0;
        font-weight: 400;
      }

      h4, p {
        color: white
      }

      .backlink {
        display: flex;
        align-items: center;
        margin: 1.5rem 0;

        &::before {
          display: block;
          width: 14px;
          height: 14px;
          content: "";
          background-image: url("./../images/common/arrow-left-green.svg");
          background-repeat: no-repeat;
          background-size: 14px 14px;
          margin-right: 5px;
          transform: translateY(1px);
        }

        &__link {
          font-size: 0.9rem;
          color: $brand-green;
          text-decoration: underline;
          line-height: 14px;
          display: inline-block;
        }
      }
    }

    &__content {
      grid-area: content;
      padding: 0 5rem 3rem 5rem;
      background-color: $brand-gray-lt;

      @include media($base-breakpoint, max) {
        padding: 0 2rem 3rem 2rem;
      }

      img {
        width: 100%;
      }

      ul {

        li {
          display: flex;
          align-items: center;
          margin-bottom: 15px;

          &::before {
            display: block;
            content: "";
            background-image: url("./../images/common/chevron-right.svg");
            background-size: 100%;
            width: 7px;
            height: 11px;
            margin-right: 10px;
          }
        }
      }
    }

    &__sidebar {
      grid-area: sidebar;
      position: relative;
      margin-bottom: -82px;

      @include media($base-breakpoint, max) {
        justify-self: center;
        margin-bottom: 3rem;
      }

      .vacancy-sidebar {

        &__content {
          font-size: 0.9rem;
          padding: 1.5rem 2rem;
          width: 254px;
          min-height: 250px;
          background-color: white;
          position: sticky;
          top: 165px;
          transform: translate(-90px, -130px);
          z-index: 15;
          box-shadow: 0 2px 15px 0 rgba(50, 50, 50, 0.12);

          @include media($base-breakpoint, max) {
            transform: initial;
            position: relative;
            top: initial;
          }

          a, .bold {
            font-weight: 500;
          }

          .vacancy-info {

            &__button {
              text-align: center;
              border-bottom: 1px solid $brand-gray-lt;
              padding-bottom: 1rem;
            }

            &__item {
              border-bottom: 1px solid $brand-gray-lt;
              padding: 1rem 0;

              p {
                margin: 0;
              }
            }

            &__title {
              color: $brand-gray-md;
              margin-bottom: 5px !important;
            }
          }
        }

        &__image {
          position: absolute;
          top: 0;
          width: 100%;
          margin-top: 3rem;
          z-index: 10;

          @include media($base-breakpoint, max) {
            display: none;
          }

          &_one, &_two {
            width: 219px;
            height: 162px;
            position: absolute;
            left: 0;
          }

          &_one {
            top: 100px;
          }

          &_two {
            top: 181px;
          }
        }
      }
    }
  }

}