.prefooter-slider {
  position: relative;

  &__slide {
    position: relative;
    margin: 0;

    &::before {
      display: none;
    }

    @include media($screen-md, max) {
      padding: 8rem 1rem 1rem 1rem;
      box-sizing: border-box;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $brand-blue;
      opacity: 0.5;
      z-index: 10;

      @include media($screen-md, max) {
        opacity: 1;
      }
    }
  }

  &__image {
    float: none;
    min-height: 283px;
    object-fit: cover;
    object-position: center right;

    @include media($screen-md, max) {
      display: none !important;
    }
  }

  &__content {
    display: grid;
    grid-template-areas: "title description";
    grid-gap: 3rem;
    align-items: center;
    position: absolute;
    z-index: 20;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: $wrapper-max-width;
    transform: translate(-50%, -50%);
    box-sizing: border-box;

    @include media(1100px, max) {
      padding: 5rem 2rem;
    }

    @include media($screen-md, max) {
      grid-gap: 2rem;
      grid-template-areas: "title"
                           "description";
      position: relative;
      top: initial;
      left: initial;
      transform: none;
      border: 1px solid $brand-green;
    }
  }

  &__title {
    grid-area: title;
    color: white;
    font-family: $font-secondary;
    font-size: 1.4rem;
    max-width: 400px;
    font-weight: 400;
    margin: 0;
    white-space: nowrap;

    @include media ($screen-md, max) {
      font-size: 1.34rem;
    }
  }

  &__description {
    grid-area: description;
    display: grid;
    grid-template-columns: max-content auto;
    grid-gap: 3rem;
    align-items: center;
    color: white;
    margin: 0;
    position: relative;

    @include media ($screen-md, max) {
      grid-template-columns: 1fr;
      grid-gap: 2rem;
    }

    p {
      margin: 0;

      @include media ($screen-md, max) {

        &:nth-child(1) {
          order: 2;
        }

        &:nth-child(1) {
          order: 1;
        }
      }
    }
  }
}