.expertise-page-list {

  &__header {
    color: $brand-blue;
    font-family: $font-primary;
    font-size: 1.23rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2.16px;
    margin: 0 0 3rem 0;
  }

  &__pages {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(254px, 1fr));
    justify-content: space-between;
    grid-gap: 1rem;
  }

  .page-list-item {
    position: relative;
    min-height: 312px;
    background-size: cover;
    background-position-x: right;
    background-color: white;

    &::after {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: white;
      opacity: 0;
      transition: 0.3s opacity ease;
      z-index: 10;
    }

    &:hover {

      &::after {
        opacity: 1;
      }

      .page-list-item__text {
        color: $brand-gray;
      }

      .page-list-item__description {
        visibility: visible;
      }

      .page-list-item__link {

        &::before {
          margin-left: 100%;
        }

        a {
          opacity: 1;
        }
      }
    }

    &__thumbnail {
      background-color: white;
    }

    &__text {
      display: grid;
      grid-template-areas: "title"
                           "description"
                            "link";
      grid-template-rows: max-content 1fr auto;
      align-items: end;
      color: white;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 2rem;
      box-sizing: border-box;
      z-index: 20;
      transition: 0.3s color ease;
    }

    &__title {
      grid-area: title;
      font-family: $font-secondary;
      font-size: 1.3rem;
    }

    &__description {
      grid-area: description;
      visibility: hidden;
      margin-bottom: 1rem;
    }

    &__link {
      grid-area: link;
      min-height: 26px;
      transform: translateY(6px);

      &::before {
        display: block;
        content: "";
        background-image: url("./../images/common/chevron-right.svg");
        background-size: 100%;
        width: 17px;
        height: 26px;
        position: absolute;
        left: 0;
        bottom: 50%;
        transform: translateY(50%);
        transition: 0.4s margin-left ease;
      }

      a {
        line-height: 26px;
        opacity: 0;
        transition: 0.3s opacity ease;
        transition-delay: 0.05s;
      }
    }
  }
}