.news-page-list {

  &__header {
    color: $brand-blue;
    font-family: $font-primary;
    font-size: 1.23rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2.16px;
    margin: 0 0 3rem 0;
    max-width: 160px;
  }

  &__pages {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px , 1fr));
    grid-gap: 2rem;
  }

  .page-list-item {
    padding:  0 1rem;
    border-left: 2px solid $brand-green;

    &__text {
      @include media($screen-sm, max) {
        padding-bottom: 2rem;
      }
    }

    &__title {
      font-family: $font-secondary;
      margin: 0.8rem 0;
    }

    &__date {
      font-size: 0.9rem;
    }

    &__link {
      text-decoration: underline;
    }
  }
}