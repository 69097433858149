.autonav {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;

  @include media($screen-md, max) {
    display: block;

    &_collapsed {
      display: none;
    }
  }

  &__item {

    @include media($screen-md, max) {
      min-height: 46px;
    }

    &:hover {
      color: $brand-green;

      .autonav__item_has-dropdown {

        &::after {
          color: $brand-green;
        }
      }
    }

    &_selected {

    }

    &_path-selected {

    }

    &_has-dropdown {
      display: flex;
      align-items: center;

      @include media($screen-md, max) {
        display: block;
        position: relative;
      }
    }

    &_has-icon {

      a {
        font-size: 1rem;
      }
    }

    &_hidden-sm {
      @include media($screen-sm, max) {
        display: none !important;
      }
    }

    &:not(:last-child) {
      margin-right: 1rem;

      @include media ($screen-md, max) {
        margin-right: 0;
        margin-bottom: 1.8rem;
      }
    }
  }

  &__link {
    color: $brand-gray;
    font-size: 0.9rem;
    font-weight: 500;

    @include media($screen-md, max) {
      font-size: 1.55rem;
      display: block;
    }

    &_selected {

    }

    &_path-selected {

    }

    &_has-dropdown {
      display: flex;
      align-items: center;

      &::after {
        display: block;
        content: "";
        background-image: url("./../images/common/chevron-down.svg");
        background-size: 10px 10px;
        width: 10px;
        height: 10px;
        transform: translateY(2px);
        margin-left: 3px;
        transition: 0.3s transform ease;
      }

      &:hover {

        &::after {
          transform: translateY(2px) rotate(180deg);
        }
      }
    }

    &_phone {


    }

    &_email {
      transform: translateY(-1px);
      display: inline-block;
    }
  }

  &__dropdown {
    padding: 0.8rem 0;
    margin: 0;
    list-style: none;
    position: absolute;
    top: 100%;
    left: 0;
    opacity: 0;
    visibility: hidden;
    z-index: 20;
    width: 100%;
    transition: 0.3s opacity ease, 0.1s visibility;
    background-color: white;
    transition-delay: 0.3s, 0.2s;
    margin-top: 2px;

    @include media($screen-md, max) {
      position: relative;
      height: 0;
      padding: 0;
      transition-delay: 0s, 0s;
      margin-top: 1rem;
    }

    &_visible {
      opacity: 1;
      visibility: visible;
      transition-delay: 0.1s, 0s;
    }

    &_opened {
      opacity: 1;
      visibility: visible;
      height: 100%;
      transition-delay: 0s, 0s;
    }
  }

  &__dropdown-item {
    margin-bottom: 0.4rem;
  }

  &__dropdown-link {
    font-size: 0.9rem;
    font-weight: 500;
    color: $brand-gray;

    &_labeled {
      text-transform: uppercase;

      span {
        color: $brand-gray-md;
        display: block;
        text-transform: capitalize;
      }
    }
  }
}