$font-primary: "Barlow", Helvetica, Arial, Verdana, Tahoma, sans-serif;
$font-secondary: "Gimlet Text", Helvetica, Arial, Verdana, Tahoma, sans-serif;
$font-leading: "Lato", Helvetica, Arial, Verdana, Tahoma, sans-serif;

@font-face {
  font-family: 'Barlow';
  src: url('../fonts/Barlow/Barlow-BoldItalic.eot');
  src: url('../fonts/Barlow/Barlow-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Barlow/Barlow-BoldItalic.woff2') format('woff2'),
  url('../fonts/Barlow/Barlow-BoldItalic.woff') format('woff'),
  url('../fonts/Barlow/Barlow-BoldItalic.ttf') format('truetype'),
  url('../fonts/Barlow/Barlow-BoldItalic.svg#Barlow-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: url('../fonts/Barlow/Barlow-Regular.eot');
  src: url('../fonts/Barlow/Barlow-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Barlow/Barlow-Regular.woff2') format('woff2'),
  url('../fonts/Barlow/Barlow-Regular.woff') format('woff'),
  url('../fonts/Barlow/Barlow-Regular.ttf') format('truetype'),
  url('../fonts/Barlow/Barlow-Regular.svg#Barlow-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: url('../fonts/Barlow/Barlow-ExtraLightItalic.eot');
  src: url('../fonts/Barlow/Barlow-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Barlow/Barlow-ExtraLightItalic.woff2') format('woff2'),
  url('../fonts/Barlow/Barlow-ExtraLightItalic.woff') format('woff'),
  url('../fonts/Barlow/Barlow-ExtraLightItalic.ttf') format('truetype'),
  url('../fonts/Barlow/Barlow-ExtraLightItalic.svg#Barlow-ExtraLightItalic') format('svg');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: url('../fonts/Barlow/Barlow-Bold.eot');
  src: url('../fonts/Barlow/Barlow-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Barlow/Barlow-Bold.woff2') format('woff2'),
  url('../fonts/Barlow/Barlow-Bold.woff') format('woff'),
  url('../fonts/Barlow/Barlow-Bold.ttf') format('truetype'),
  url('../fonts/Barlow/Barlow-Bold.svg#Barlow-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: url('../fonts/Barlow/Barlow-SemiBold.eot');
  src: url('../fonts/Barlow/Barlow-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Barlow/Barlow-SemiBold.woff2') format('woff2'),
  url('../fonts/Barlow/Barlow-SemiBold.woff') format('woff'),
  url('../fonts/Barlow/Barlow-SemiBold.ttf') format('truetype'),
  url('../fonts/Barlow/Barlow-SemiBold.svg#Barlow-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: url('../fonts/Barlow/Barlow-LightItalic.eot');
  src: url('../fonts/Barlow/Barlow-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Barlow/Barlow-LightItalic.woff2') format('woff2'),
  url('../fonts/Barlow/Barlow-LightItalic.woff') format('woff'),
  url('../fonts/Barlow/Barlow-LightItalic.ttf') format('truetype'),
  url('../fonts/Barlow/Barlow-LightItalic.svg#Barlow-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: url('../fonts/Barlow/Barlow-ExtraBold.eot');
  src: url('../fonts/Barlow/Barlow-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Barlow/Barlow-ExtraBold.woff2') format('woff2'),
  url('../fonts/Barlow/Barlow-ExtraBold.woff') format('woff'),
  url('../fonts/Barlow/Barlow-ExtraBold.ttf') format('truetype'),
  url('../fonts/Barlow/Barlow-ExtraBold.svg#Barlow-ExtraBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: url('../fonts/Barlow/Barlow-Italic.eot');
  src: url('../fonts/Barlow/Barlow-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Barlow/Barlow-Italic.woff2') format('woff2'),
  url('../fonts/Barlow/Barlow-Italic.woff') format('woff'),
  url('../fonts/Barlow/Barlow-Italic.ttf') format('truetype'),
  url('../fonts/Barlow/Barlow-Italic.svg#Barlow-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: url('../fonts/Barlow/Barlow-Medium.eot');
  src: url('../fonts/Barlow/Barlow-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Barlow/Barlow-Medium.woff2') format('woff2'),
  url('../fonts/Barlow/Barlow-Medium.woff') format('woff'),
  url('../fonts/Barlow/Barlow-Medium.ttf') format('truetype'),
  url('../fonts/Barlow/Barlow-Medium.svg#Barlow-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: url('../fonts/Barlow/Barlow-BlackItalic.eot');
  src: url('../fonts/Barlow/Barlow-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Barlow/Barlow-BlackItalic.woff2') format('woff2'),
  url('../fonts/Barlow/Barlow-BlackItalic.woff') format('woff'),
  url('../fonts/Barlow/Barlow-BlackItalic.ttf') format('truetype'),
  url('../fonts/Barlow/Barlow-BlackItalic.svg#Barlow-BlackItalic') format('svg');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: url('../fonts/Barlow/Barlow-SemiBoldItalic.eot');
  src: url('../fonts/Barlow/Barlow-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Barlow/Barlow-SemiBoldItalic.woff2') format('woff2'),
  url('../fonts/Barlow/Barlow-SemiBoldItalic.woff') format('woff'),
  url('../fonts/Barlow/Barlow-SemiBoldItalic.ttf') format('truetype'),
  url('../fonts/Barlow/Barlow-SemiBoldItalic.svg#Barlow-SemiBoldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: url('../fonts/Barlow/Barlow-MediumItalic.eot');
  src: url('../fonts/Barlow/Barlow-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Barlow/Barlow-MediumItalic.woff2') format('woff2'),
  url('../fonts/Barlow/Barlow-MediumItalic.woff') format('woff'),
  url('../fonts/Barlow/Barlow-MediumItalic.ttf') format('truetype'),
  url('../fonts/Barlow/Barlow-MediumItalic.svg#Barlow-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: url('../fonts/Barlow/Barlow-ExtraBoldItalic.eot');
  src: url('../fonts/Barlow/Barlow-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Barlow/Barlow-ExtraBoldItalic.woff2') format('woff2'),
  url('../fonts/Barlow/Barlow-ExtraBoldItalic.woff') format('woff'),
  url('../fonts/Barlow/Barlow-ExtraBoldItalic.ttf') format('truetype'),
  url('../fonts/Barlow/Barlow-ExtraBoldItalic.svg#Barlow-ExtraBoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: url('../fonts/Barlow/Barlow-Thin.eot');
  src: url('../fonts/Barlow/Barlow-Thin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Barlow/Barlow-Thin.woff2') format('woff2'),
  url('../fonts/Barlow/Barlow-Thin.woff') format('woff'),
  url('../fonts/Barlow/Barlow-Thin.ttf') format('truetype'),
  url('../fonts/Barlow/Barlow-Thin.svg#Barlow-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: url('../fonts/Barlow/Barlow-ExtraLight.eot');
  src: url('../fonts/Barlow/Barlow-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Barlow/Barlow-ExtraLight.woff2') format('woff2'),
  url('../fonts/Barlow/Barlow-ExtraLight.woff') format('woff'),
  url('../fonts/Barlow/Barlow-ExtraLight.ttf') format('truetype'),
  url('../fonts/Barlow/Barlow-ExtraLight.svg#Barlow-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: url('../fonts/Barlow/Barlow-Light.eot');
  src: url('../fonts/Barlow/Barlow-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Barlow/Barlow-Light.woff2') format('woff2'),
  url('../fonts/Barlow/Barlow-Light.woff') format('woff'),
  url('../fonts/Barlow/Barlow-Light.ttf') format('truetype'),
  url('../fonts/Barlow/Barlow-Light.svg#Barlow-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: url('../fonts/Barlow/Barlow-ThinItalic.eot');
  src: url('../fonts/Barlow/Barlow-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Barlow/Barlow-ThinItalic.woff2') format('woff2'),
  url('../fonts/Barlow/Barlow-ThinItalic.woff') format('woff'),
  url('../fonts/Barlow/Barlow-ThinItalic.ttf') format('truetype'),
  url('../fonts/Barlow/Barlow-ThinItalic.svg#Barlow-ThinItalic') format('svg');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: url('../fonts/Barlow/Barlow-Black.eot');
  src: url('../fonts/Barlow/Barlow-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Barlow/Barlow-Black.woff2') format('woff2'),
  url('../fonts/Barlow/Barlow-Black.woff') format('woff'),
  url('../fonts/Barlow/Barlow-Black.ttf') format('truetype'),
  url('../fonts/Barlow/Barlow-Black.svg#Barlow-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gimlet Text';
  src: url('../fonts/GimletText/GimletText-Regular.eot');
  src: url('../fonts/GimletText/GimletText-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GimletText/GimletText-Regular.woff2') format('woff2'),
  url('../fonts/GimletText/GimletText-Regular.woff') format('woff'),
  url('../fonts/GimletText/GimletText-Regular.ttf') format('truetype'),
  url('../fonts/GimletText/GimletText-Regular.svg#GimletText-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gimlet Text';
  src: url('../fonts/GimletText/GimletText-Medium.eot');
  src: url('../fonts/GimletText/GimletText-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GimletText/GimletText-Medium.woff2') format('woff2'),
  url('../fonts/GimletText/GimletText-Medium.woff') format('woff'),
  url('../fonts/GimletText/GimletText-Medium.ttf') format('truetype'),
  url('../fonts/GimletText/GimletText-Medium.svg#GimletText-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato Hairline';
  src: url('../fonts/Lato/Lato-HairlineItalic.eot');
  src: url('../fonts/Lato/Lato-HairlineItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato/Lato-HairlineItalic.woff2') format('woff2'),
  url('../fonts/Lato/Lato-HairlineItalic.woff') format('woff'),
  url('../fonts/Lato/Lato-HairlineItalic.ttf') format('truetype'),
  url('../fonts/Lato/Lato-HairlineItalic.svg#Lato-HairlineItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato/Lato-Bold.eot');
  src: url('../fonts/Lato/Lato-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato/Lato-Bold.woff2') format('woff2'),
  url('../fonts/Lato/Lato-Bold.woff') format('woff'),
  url('../fonts/Lato/Lato-Bold.ttf') format('truetype'),
  url('../fonts/Lato/Lato-Bold.svg#Lato-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato/Lato-Regular.eot');
  src: url('../fonts/Lato/Lato-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato/Lato-Regular.woff2') format('woff2'),
  url('../fonts/Lato/Lato-Regular.woff') format('woff'),
  url('../fonts/Lato/Lato-Regular.ttf') format('truetype'),
  url('../fonts/Lato/Lato-Regular.svg#Lato-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato Hairline';
  src: url('../fonts/Lato/Lato-Hairline.eot');
  src: url('../fonts/Lato/Lato-Hairline.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato/Lato-Hairline.woff2') format('woff2'),
  url('../fonts/Lato/Lato-Hairline.woff') format('woff'),
  url('../fonts/Lato/Lato-Hairline.ttf') format('truetype'),
  url('../fonts/Lato/Lato-Hairline.svg#Lato-Hairline') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato/Lato-Italic.eot');
  src: url('../fonts/Lato/Lato-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato/Lato-Italic.woff2') format('woff2'),
  url('../fonts/Lato/Lato-Italic.woff') format('woff'),
  url('../fonts/Lato/Lato-Italic.ttf') format('truetype'),
  url('../fonts/Lato/Lato-Italic.svg#Lato-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato/Lato-Light.eot');
  src: url('../fonts/Lato/Lato-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato/Lato-Light.woff2') format('woff2'),
  url('../fonts/Lato/Lato-Light.woff') format('woff'),
  url('../fonts/Lato/Lato-Light.ttf') format('truetype'),
  url('../fonts/Lato/Lato-Light.svg#Lato-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato/Lato-Black.eot');
  src: url('../fonts/Lato/Lato-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato/Lato-Black.woff2') format('woff2'),
  url('../fonts/Lato/Lato-Black.woff') format('woff'),
  url('../fonts/Lato/Lato-Black.ttf') format('truetype'),
  url('../fonts/Lato/Lato-Black.svg#Lato-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato/Lato-LightItalic.eot');
  src: url('../fonts/Lato/Lato-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato/Lato-LightItalic.woff2') format('woff2'),
  url('../fonts/Lato/Lato-LightItalic.woff') format('woff'),
  url('../fonts/Lato/Lato-LightItalic.ttf') format('truetype'),
  url('../fonts/Lato/Lato-LightItalic.svg#Lato-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato/Lato-BoldItalic.eot');
  src: url('../fonts/Lato/Lato-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato/Lato-BoldItalic.woff2') format('woff2'),
  url('../fonts/Lato/Lato-BoldItalic.woff') format('woff'),
  url('../fonts/Lato/Lato-BoldItalic.ttf') format('truetype'),
  url('../fonts/Lato/Lato-BoldItalic.svg#Lato-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato/Lato-BlackItalic.eot');
  src: url('../fonts/Lato/Lato-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato/Lato-BlackItalic.woff2') format('woff2'),
  url('../fonts/Lato/Lato-BlackItalic.woff') format('woff'),
  url('../fonts/Lato/Lato-BlackItalic.ttf') format('truetype'),
  url('../fonts/Lato/Lato-BlackItalic.svg#Lato-BlackItalic') format('svg');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-secondary;
}

h3 {
  font-weight: 400;
  font-size: 1.55rem;
  line-height: 2rem;
}

h4, h5, h6 {
  font-weight: 500;
  margin: 0;
}


