.careers-page-list {
  max-width: 704px;
  width: 100%;

  &__header {
    text-align: center;
    margin-bottom: 3rem;
  }

  &__pages {

  }

  .page-list-item {
    border-bottom: 1px solid lighten($brand-gray-md, 20);

    &:first-child {
      border-top: 1px solid lighten($brand-gray-md, 20);
    }

    &__thumbnail {

    }

    &__text {
      display: grid;
      grid-template-areas: "title link"
                           "description link";
      grid-template-columns: 1fr auto;
      padding: 2rem;
      grid-gap: 10px;

      @include media($screen-sm, max) {
        grid-template-columns: 1fr;
        grid-template-areas: "title"
                             "description"
                             "link";
      }
    }

    &__title {
      grid-area: title;
    }

    &__description {
      grid-area: description;
      font-size: 0.9rem;
    }

    &__link {
      grid-area: link;
    }
  }
}