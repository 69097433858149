.featured-experts-list {

  &__title {

  }

  &__experts {
    font-size: 0.9rem;
    border-top: 1px solid $brand-gray-lt;
  }

  .person-card {
    display: grid;
    grid-template-areas: "title photo"
                         "position photo";
    grid-template-columns: 1fr 50px;
    padding: .5rem 0;
    border-bottom: 1px solid $brand-gray-lt;

    &__link {
      font-weight: 500;
    }

    &__title {
      grid-area: title;
    }

    &__photo {
      grid-area: photo;
      align-self: center;
      justify-self: center;

      img {
        border-radius: 50%;
      }
    }

    &__position {
      grid-area: position;
    }
  }
}