.image-slider {

  &__slide {
    position: relative;
    margin: 0;

    &::before, &:after {
      display: none;
    }
  }

  &__link {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    z-index: 30;
  }

  &__image {
    float: none;
    min-height: 283px;
    object-fit: cover;
    object-position: center right;
  }

  &__content {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: $wrapper-max-width;
    transform: translate(-50%, -50%);
    box-sizing: border-box;

    @include media(1100px, max) {
      padding: 2rem;
    }

    @include media($screen-md, max) {
      position: relative;
      top: initial;
      left: initial;
      transform: none;
    }
  }

  &__title {
    color: $brand-blue;
    font-family: $font-secondary;
    font-size: 3.44rem;
    max-width: 400px;
    font-weight: 400;
    margin: 0;

    @include media ($screen-md, max) {
      font-size: 2.85rem;
    }
  }

  &__description {
    color: $brand-blue;
    margin: 0;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1.8px;
    font-size: 0.9rem;
    position: relative;
    padding-left: calc(22px + 1rem);
    margin-top: 1rem;

    &::before {
      display: block;
      content: "";
      background-image: url("./../images/common/chevron-right.svg");
      background-size: 100%;
      width: 15px;
      height: 22px;
      position: absolute;
      left: 0;
      bottom: 50%;
      transform: translateY(50%);
    }

    p {
      margin: 0;
    }
  }
}