.page-template-home {

  .firm {
    @include section;

    @include media($screen-md, max) {
      padding: 0;
    }

    &__wrapper {
      @include wrapper;
      display: grid;
      grid-gap: 4rem;
      grid-template-areas: "image content";
      grid-template-columns: 314px auto;

      @include media($screen-md, max) {
        grid-template-areas: "content"
                             "image";
        grid-template-columns: 1fr;
        grid-gap: 0;
      }
    }

    &__image {
      grid-area: image;
      position: relative;
      padding-bottom: 100px;

      @include media($screen-md, max) {
        padding: 0;
        overflow: hidden;
        margin-top: -2rem;
      }

      &one, &_two {
        position: absolute;
        top: 0;
        left: 0;
      }

      &_two {
        transform: translate(50px, 50px);
      }
    }

    &__content {
      grid-area: content;
      padding: 58px 0;

      @include media($screen-md, max) {
        padding-bottom: 0;
      }
    }
  }

  .expertise {
    @include section;
    background-color: $brand-gray-lt;

    @include media($screen-md, max) {
      padding: 2rem 0;
    }

    &__wrapper {
      @include wrapper;
    }
  }

  .news {
    @include section;

    &__wrapper {
      @include wrapper;
    }
  }
}