@mixin section {
  padding: 5rem 0;

  @media only screen and (max-width: $screen-md) {
    padding: 3rem 0;
  }
}

@mixin wrapper {
  width: 100%;
  max-width: $wrapper-max-width;
  margin: 0 auto;
  box-sizing: border-box;

  @media only screen and (max-width: 1100px) {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

@mixin media($size1, $size2: min) {
  @if $size2 == min {
    @media only screen and (min-width: $size1) {
      @content;
    }
  } @else if $size2 == max {
    @media only screen and (max-width: $size1 - 1) {
      @content;
    }
  } @else {
    @media only screen and (min-width: $size1) and (max-width: $size2 - 1) {
      @content;
    }
  }
}

