.express-form {
  max-width: 592px;
  width: 100%;
  margin: 2.5rem auto;

  &__form {

    fieldset {
      padding: 0;
      border: none;
    }
  }

  &__fields {

    .form-group {

      .control-label, .text-muted {
        display: none;
      }

      input, textarea {
        width: 100%;
        padding: 9px 15px;
        border: 1px solid #dbdbdb;
        border-radius: 0;
        margin-bottom: 1rem;
        outline: none;
        box-sizing: border-box;

        &::placeholder {
          color: #989898;
          font-family: $font-primary;
          font-size: 16px;
        }
      }

      textarea {
        min-height: 205px;
      }
    }
  }
}