.page-template-careers {

  .headline {

    &__description {
      max-width: 440px;
    }
  }

  .lead {

    &__wrapper {
      width: 100%;
      max-width: $screen-lg;
      margin: 0 auto;
      padding-left: 150px;
      box-sizing: border-box;
      display: grid;
      grid-template-areas: "content image";
      grid-template-columns: 520px 1fr;

      @include media($screen-md, max) {
        grid-template-areas: "content"
        "image";
        grid-template-columns: 1fr;
        padding-left: 0;
      }
    }

    &__content {
      grid-area: content;
      padding: 5rem;

      @include media($screen-md, max) {
        padding: 5rem 2rem;
      }
    }

    &__image {
      grid-area: image;

      img {
        transform: translate(4rem, -4rem);
        min-height: 557px;
        object-fit: cover;
        object-position: center left;
        height: 100%;

        @include media($screen-md, max) {
          transform: translate(0, 0);
          min-height: 450px;
        }
      }
    }
  }

  .statement {
    background-color: $brand-green-lt;
    padding: 5rem 1rem 6.5rem 1rem;

    @include media($screen-md, max) {
      padding: 3rem 1rem 4.5rem 1rem;
    }

    &__wrapper {
      @include wrapper;
      display: grid;
      grid-template-areas: "image content";
      grid-gap: 3rem;
      margin-bottom: 3.6rem;

      @include media($screen-md, max) {
        grid-template-areas: "content"
        "image";
        grid-template-columns: 1fr;
      }
    }

    &__image {
      grid-area: image;

      @include media($screen-md, max) {
        justify-self: center;
      }

      .statement-image {
        position: relative;
        max-width: 320px;
        min-height: 171px;

        @include media($screen-md, max) {
          justify-self: center;
        }

        img {
          max-width: 320px;
          transform: translateX(-100px);

          @include media($screen-md, max) {
            transform: none;
          }
        }

        &::after {
          position: absolute;
          top: 50px;
          right: 120px;
          display: block;
          content: "";
          width: 165px;
          height: 180px;
          background-image: url("./../images/careers/statement-section-bg.png");
          background-repeat: no-repeat;
          background-size: 100%;

          @include media($screen-md, max) {
            right: 20px;
          }
        }
      }
    }

    &__content {
      grid-area: content;
      padding: 2rem 50px 0 0;

      @include media($screen-md, max) {
        padding: 0;
      }
    }
  }

  .opportunities {

    &__wrapper{
      @include wrapper;
      display: grid;
      grid-template-columns: 1fr 1fr;

      @include media($screen-md, max) {
        grid-template-columns: 1fr;
      }
    }

    &__header {
      padding: 5rem 1rem;

      .op-header {
        border-left: 1px solid $brand-green;
        padding-left: 2rem;
        max-width: 410px;

        p {
          &:first-child {
            margin-top: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &__content {
      display: flex;
      justify-content: center;
      padding: 5rem 2rem 6rem 2rem;
      background-color: $brand-gray-lt;

      @include media($screen-md, max) {
        padding: 3rem 2rem 4rem 2rem;
      }
    }

  }
}