.articles-search {
  max-width: 984px;
  margin: 0 auto 3rem auto;

  &__wrapper {
    display: grid;
    grid-template-columns: 234px 234px;
    grid-column-gap: 1rem;

    @include media($screen-sm, max) {
      grid-template-columns: 1fr;
    }
  }

  &__search, &__categories {

  }

  &__field {
    display: inline-block;
    position: relative;

    @include media($screen-sm, max) {
      width: 100%;
      margin-bottom: 1rem;
    }

    .loupe {
      background-image: url("./../images/common/loupe.svg");
      background-size: 100%;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(-10px, -50%);
      cursor: pointer;
    }
  }

  &__text {
    border: 1px solid lighten($brand-gray-md, 30);
    padding: 0.5rem 1rem;
    font-size: 1rem;
    outline: none;

    @include media($screen-sm, max) {
      width: 100%;
    }
  }

  &__categories {

    select {
      color: $brand-gray-md;
      border: 1px solid lighten($brand-gray-md, 30);
      padding: 0.5rem 1rem;
      font-size: 1rem;
      outline: none;
      overflow: hidden;
      max-width: 234px;

      @include media($screen-sm, max) {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}