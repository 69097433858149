.page-template-firm {

  &.is-edit-mode {

    .diversity {

      &__logos {
        display: block;
      }
    }
  }

  .lead {
    padding: 5rem 1rem;

    &__wrapper {
      @include wrapper;
      display: grid;
      grid-gap: 10rem;
      grid-template-areas: "content image";
      grid-template-columns: auto 250px;
      padding: 0 0 1rem 5rem;

      @include media($screen-md, max) {
        grid-template-areas: "content"
                             "image";
        grid-template-columns: 1fr;
        grid-gap: 0;
      }
    }

    &__content {
      grid-area: content;
    }

    &__image {
      grid-area: image;
      position: relative;
      min-height: 199px;

      &_one, &_two {
        position: absolute;
        top: 0;
        right: 0;
      }

      &_one {
        transform: translate(-50px, 0);
        z-index: 20;
        width: 140px;
        height: 100%;
      }

      &_two {
        transform: translate(0, 0);
        z-index: 10;
        width: 130px;
        height: 100%;
      }
    }
  }

  .team {
    background-color: $brand-gray-lt;

    &__wrapper {
      width: 100%;
      max-width: $screen-lg;
      margin: 0 auto;
      box-sizing: border-box;
      display: grid;
      grid-template-areas: "image content";
      grid-template-columns: 1fr 1fr;

      @include media($screen-md, max) {
        grid-template-areas: "image"
                             "content";
        grid-template-columns: 1fr;
      }
    }

    &__image {
      grid-area: image;
    }

    .team-content {
      grid-area: content;
      padding: 5rem 5rem 0 5rem;

      @include media($screen-md, max) {
        padding: 3rem;
      }

      &__wrapper {
        max-width: 438px;
      }
    }
  }

  .diversity {
    padding: 5rem 1rem;

    &__wrapper {
      @include wrapper;
      display: grid;
      grid-gap: 10rem;
      grid-template-areas: "content logos";
      grid-template-columns: auto 250px;
      padding: 0 0 1rem 5rem;

      @include media($screen-md, max) {
        grid-template-areas: "content"
                             "logos";
        grid-template-columns: 1fr;
        grid-gap: 0;
        padding-bottom: 0;
      }
    }

    &__content {
      grid-area: content;
      margin-top: 5rem;

      ul {

        li {
          display: block;

          &::before {
            top: 5px;
          }
        }
      }
    }

    &__logos-wrapper {
      grid-area: logos;
    }

    //&__image {
    //  grid-area: image;
    //  position: relative;
    //  min-height: 199px;
    //
    //  &_one, &_two {
    //    position: absolute;
    //    top: 0;
    //    right: 0;
    //  }
    //
    //  &_one {
    //    transform: translate(-50px, 0);
    //    z-index: 20;
    //    width: 140px;
    //    height: 100%;
    //  }
    //
    //  &_two {
    //    transform: translate(0, 0);
    //    z-index: 10;
    //    width: 130px;
    //    height: 100%;
    //  }
    //}

    &__image {
      grid-area: image;
      position: relative;
      padding-bottom: 100px;

      @include media($screen-md, max) {
        padding: 0;
        overflow: hidden;
        margin-top: -2rem;
      }

      &one, &_two {
        position: absolute;
        top: 0;
        left: 0;
      }

      &_two {
        transform: translate(50px, 50px);
      }
    }

    &__logos {
      display: flex;
      flex-direction: column;
      margin-top: 220px;

      #genderEqualityCharter {
        margin-top: 30px;
      }

      #tupuToa {
        margin-top: 30px;
      }

      @include media($screen-md, max) {
        align-items: center;
        margin-top: 0;

        #genderEqualityCharter {
          margin-top: 0;
        }

        #tupuToa {
          margin-top: 0;
        }
      }

      img {
        margin-top: 2rem;

        &.img-responsive {
          width: fit-content;
        }
      }
    }
  }

  .statement {
    padding: 5rem 1rem;

    &__wrapper {
      position: relative;
      width: 100%;
      max-width: 917px;
      margin: 0 auto;
      box-sizing: border-box;
      border: 1px solid $brand-green;

      @include media($screen-md, max) {
        margin-bottom: 60px;
      }

      &::after {
        display: block;
        content: "";
        width: 314px;
        height: 162px;
        background-image: url("./../images/firm/statement-section-image.png");
        position: absolute;
        right: 0;
        bottom: 0;
        transform: translate(80px, 30px);

        @include media($screen-md, max) {
          transform: translate(10px, 130px);
        }
      }
    }

    &__content {
      max-width: 670px;
      padding: 2rem 5rem 3rem 5rem;

      @include media($screen-md, max) {
        max-width: 100%;
        padding: 2rem;

        p {
          padding: initial !important;
          max-width: 100% !important;
        }
      }
    }
  }

  .support {
    padding: 3rem 2rem;

    &__wrapper {
      display: grid;
      grid-template-areas: "headline content";
      grid-template-columns: 300px 1fr;
      grid-gap: 4rem;
      position: relative;
      width: 100%;
      max-width: 917px;
      margin: 0 auto;
      box-sizing: border-box;
      border-left: 1px solid $brand-green;

      @include media($screen-md, max) {
        grid-template-areas: "headline"
                              "content";
        border-left: none;
      }
    }

    &__headline {
      grid-area: headline;
      padding-left: 3rem;

      p {
        margin: 0;
      }

      @include media($screen-md, max) {
        border-left: 1px solid $brand-green;
      }
    }

    &__content {
      grid-area: content;

      ul.grid-list {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

        li {
          display: flex;
          align-items: center;
          margin-bottom: 15px;

          &::before {
            display: block;
            content: "";
            background-image: url("./../images/common/chevron-right.svg");
            background-size: 100%;
            width: 7px;
            height: 11px;
            margin-right: 10px;
          }
        }
      }
    }
  }

  .careers {

  }
}