.header {
  padding: 1rem 0;
  position: relative;

  @include media($screen-md, max) {
    padding: 0.82rem 0;
  }

  &__wrapper {
    @include wrapper;
    display: grid;
    grid-template-areas: "logo menu toggle";
    grid-template-columns: 200px 1fr;
    align-items: center;
    max-width: $wrapper-max-width;

    @include media($screen-md, max) {
      grid-template-columns: 171px 1fr;
    }
  }

  &__logo {
    grid-area: logo;

    img {
      display: inline-block;
      width: 100%;
      height: 40px;
      object-fit: cover;
      object-position: top left;
    }
  }

  .header-menu {
    display: grid;
    grid-template-areas: "navigation additional";
    grid-area: menu;
    justify-self: end;
    align-self: center;
    box-sizing: border-box;

    @include media($screen-md, max) {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      z-index: 20;
      padding: 1rem 2.2rem;
      background-color: white;
      grid-template-areas: "additional"
                           "navigation";
      height: 100vh;
      align-content: start;
      overflow-y: scroll;

      &.js-collapsed {
        visibility: collapse;

        .header-menu__additional {

          .dropdown-additional {
            display: none;
            visibility: collapse;
            opacity: 0;
            transition: none;
          }
        }
      }
    }

    &__navigation {
      grid-area: navigation;

      @include media($screen-md, max) {
        padding-top: 5rem;
      }
    }

    &__info {
      margin-top: 2rem;
      padding-bottom: 3rem;
    }

    .header-menu-info {
      font-size: 1.25rem;
      line-height: 1.35rem;

      &__item {
        margin-bottom: 1.8rem;
      }

      &__label {

      }

      &__link {

      }
    }

    &__additional {
      grid-area: additional;
      justify-self: end;
      align-self: center;
      padding-left: 1rem;

      .phone-block {
        max-width: 240px;
        text-align: right;
      }

      .search-block {

        &__submit {
          min-height: auto;
        }

        &__wrapper {
          max-width: 230px;

          @include media($screen-md, max) {
            max-width: 100%;
          }
        }

        &__text {
          width: 100%;
          box-sizing: border-box;

          @include media($screen-md, max) {
            min-height: 54px;
          }
        }
      }

      @include media($screen-md, max) {
        display: block;
        width: 100%;
        padding-left: 0;

        .autonav {

          &__link {

            &_search {
              display: none;
            }
          }

          &__item {

            &_has-dropdown {

              &::after {
                display: none;
              }
            }
          }
        }

        .dropdown-additional {
          display: block;
          position: relative;
          visibility: visible;
          opacity: 1;
          transition: none;
          margin-bottom: 1.8rem;
        }
      }
    }

    &__toggle {
      grid-area: toggle;
      width: 20px;
      height: 20px;
      cursor: pointer;
      position: relative;
      transform: translateY(-8px);

      @include media($screen-md, min) {
        display: none;
      }

      > div {
        height: 2px;
        width: 100%;
        background-color: lighten($brand-gray, 10);
        opacity: 1;
        transition: 0.3s transform ease, 0.3s opacity ease;

        &:not(:last-child) {
          margin-bottom: 4px;
        }
      }

      &.active {

        > div {
          position: absolute;
          top: 50%;
        }

        div:nth-child(1) {
          transform: rotate(45deg)
        }

        div:nth-child(2) {
          opacity: 0;
        }

        div:nth-child(3) {
          transform: rotate(-45deg);
        }
      }
    }
  }
}