.page-template-our-people {

  .headline {
    background-color: $brand-gray;

    &__wrapper {
      position: relative;
      background-image: url("./../images/our_people/our_people_headline_image_one.png");
      background-repeat: no-repeat;
      background-position: top right;
      min-height: 400px;

      @include media ($screen-sm, max) {
        padding: 300px 2rem 3rem 2rem;
        min-height: 950px;
      }

      &::after {
        display: block;
        content: "";
        width: 148px;
        height: 192px;
        background-image: url("./../images/our_people/our_people_headline_image_two.png");
        position: absolute;
        top: 0;
        right: 88px;
      }
    }
  }

  .team-members {
    margin-top: -140px;
    transform: translateX(-1rem);
    margin-bottom: 8rem;
    padding: 0 2rem;

    @include media($screen-md, max) {
      transform: translateX(0);
    }

    @include media ($screen-sm, max) {
      margin-top: -380px;
    }

    &__wrapper {
      width: 100%;
      max-width: 922px;
      margin: 0 auto;
    }
  }
}
